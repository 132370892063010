import { Picker } from 'emoji-picker-element';
import ru from 'emoji-picker-element/i18n/ru_RU';

const createPicker = () => {
  const picker = new Picker({
    i18n: ru,
    locale: 'ru',
  });

  picker.classList.add('light');

  return picker;
};

export default createPicker;