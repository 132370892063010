import { onDomChanges } from '../components/dynamic/observer';

onDomChanges(() => {
  const form = document.querySelector('[data-client-form]');
  if (form) {
    form.querySelectorAll('input')
      .forEach((i) => {
        i.addEventListener('focus', (e) => {
          const { target } = e;
          target.setAttribute('autocomplete', 'off');
          target.setAttribute('readonly', 'true');
          setTimeout(() => target.removeAttribute('readonly'), 10);
        });
      });
  }
});