import {debounce} from 'lodash';
import {onDomReady} from '../../components/dynamic/observer';
import Jax from '../../components/jax/jax';

class ClientFilter {
  constructor() {
    this.form = document.querySelector('[data-client-filter]');
    this.content = document.querySelector('[data-clients-list]');

    // Отменяем отправку формы по Enter
    this.form.addEventListener('keydown', (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });

    this.init();
  }

  init() {
    this.form.addEventListener('input', debounce(() => {
      const jax = new Jax(this.form.getAttribute('action'));
      jax.send(new FormData(this.form)).then((html) => {
        const page = document.createElement('div');
        page.innerHTML = html;
        const newContent = page.querySelector('[data-clients-list]');
        this.content.innerHTML = newContent.innerHTML;

        const event = new Event('DOMContentMutated');
        document.dispatchEvent(event);
      });
    }, 200));
  }
}

onDomReady(() => {
  if (document.querySelector('[data-client-filter]')) {
    new ClientFilter();
  }
});