import axios from 'axios';
import Modal from '../../components/modal/modal';
import { validatorValidateForm, validatorCleanErrors } from '../../components/forms/validation';
import createPicker from '../emoji/index';

class PublicationForm {
  constructor(detail) {
    this.groupSlotId = detail.groupSlotId;
    this.mainSlotId = detail.mainSlotId;
    this.publicationId = detail.publicationId;
    this.type = detail.type;

    this.modal = null;

    this.init();
  }

  async init() {
    await this.initModal();

    if (this.modal) {
      this.form = this.modal.content.querySelector('[data-publication-form]');
      this.formName = this.form.dataset.formName;
      this.toSlotButtons = this.modal.content.querySelectorAll('[data-to-slot]');
      this.ordCheckbox = this.form.querySelector(`#${this.formName}_marking`);
      this.emojiBlock = this.form.querySelector('[data-emoji-block]');
      this.targetBlock = this.modal.content.querySelector('[data-target-block]');
      this.createEmoji();
      this.bind();
    }
  }

  createEmoji() {
    const instance = this;
    const textField = this.form.querySelector('[data-has-emoji] textarea');
    if (this.emojiBlock) {
      const picker = createPicker();
      picker.addEventListener('emoji-click', (event) => {
        textField.value += event.detail.unicode;
      });
      this.emojiBlock.appendChild(picker);
    }
  }

  bind() {
    this.bindSubmit();
    this.bindOrdCheckbox();

    if (this.toSlotButtons.length) {
      this.bindToSlotButtons();
    }

    if (this.targetBlock) {
      this.bindTargetBlock();
      this.bindAdditionalRemove();
    }
  }

  bindTargetBlock() {
    const instance = this;
    const addLink = this.targetBlock.querySelector('[data-additional-link]');
    if (addLink) {
      addLink.addEventListener('click', (e) => {
        e.preventDefault();
        const formData = new FormData(this.form);
        axios.post('/vk/publication/get-news-target-form', formData)
          .then((response) => {
            const { data } = response;
            if (!data.success) {
            // instance.handleErrors(data.errors);
            } else {
              const currentList = instance.targetBlock.querySelector('[data-target-list]');
              const newData = document.createElement('div');
              newData.innerHTML = data.html;

              currentList.append(newData.querySelector('form'));
              document.dispatchEvent(new Event('DOMContentMutated'));
              instance.bindAdditionalRemove();
            }
          });
      });
    }
  }

  bindAdditionalRemove() {
    this.targetBlock.querySelectorAll('[data-additional-delete]:not([data-initialized="true"])').forEach((link) => {
      link.dataset.initialized = true;
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const element = link.closest('[data-target-form]');
        element.remove();
      });
    });
  }

  bindOrdCheckbox() {
    if (this.ordCheckbox) {
      const instance = this;

      instance.handleOrd(this.ordCheckbox.checked);
      this.ordCheckbox.addEventListener('change', (e) => instance.handleOrd(e.target.checked));
    }
  }

  handleOrd(show) {
    const elements = this.modal.content.querySelectorAll('[data-for-ord]');
    elements.forEach((element) => {
      element.style.display = show ? 'block' : 'none';
    });
  }

  bindSubmit() {
    const instance = this;
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      instance.submitForm();
    });
  }

  bindToSlotButtons() {
    const instance = this;
    this.toSlotButtons.forEach((button) => {
      const slotId = button.dataset.toSlot;
      button.addEventListener('click', (e) => {
        e.preventDefault();
        instance.modal.close();
        document.dispatchEvent(new CustomEvent('slot:edit', { detail: { slotId } }));
      });
    });
  }

  async submitForm() {
    this.cleanErrors();
    const formData = this.getFormData();
    await this.request(this.form.getAttribute('action'), formData);
  }

  getFormData() {
    const formData = new FormData(this.form);
    formData.set('additional', this.getAdditionalData());

    return formData;
  }

  getAdditionalData() {
    const additionalData = {};

    if (this.targetBlock) {
      const targets = [];
      const targetList = this.targetBlock.querySelector('[data-target-list]');
      const targetForms = targetList.querySelectorAll('[data-target-form]');
      targetForms.forEach((targetForm) => {
        const targetData = {};
        const formData = new FormData(targetForm);
        const targetId = targetForm.dataset.targetId;

        if (targetId) {
          formData.append('targetId', targetId);
        }

        formData.append('idPrefix', targetForm.dataset.targetForm);

        formData.forEach((value, name) => {
          targetData[name] = value;
        });

        targets.push(targetData);
      });

      additionalData.targets = targets;
    }

    return JSON.stringify(additionalData);
  }

  async request(url, formData) {
    const instance = this;
    await axios.post(url, formData)
      .then((response) => {
        const { data } = response;
        if (data.success || data.state === 'success') {
          instance.handleSuccess(data);
        } else {
          instance.handleErrors(data.errors);
        }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  handleSuccess(data) {
    this.modal.close();
    document.dispatchEvent(new CustomEvent('publication:saved'));
  }

  handleErrors(errors) {
    const generalErrors = errors[this.formName];
    if (generalErrors) {
      validatorValidateForm(this.form, this.formName, generalErrors);
    }
    if (this.targetBlock) {
      this.targetBlock.querySelectorAll('[data-target-form]').forEach((targetForm) => {
        const formName = targetForm.getAttribute('name');
        const formPrefix = targetForm.dataset.targetForm;
        const targetErrors = errors[formName];
        if (targetErrors) {
          const targetError = targetErrors[formPrefix];
          if (targetError) {
            validatorValidateForm(targetForm, formName, targetError);
          }
        }
      });
    }
  }

  cleanErrors() {
    validatorCleanErrors(this.form, this.formName);
  }

  async initModal() {
    const instance = this;
    let url = '';
    if (instance.type === 'slot') {
      url = '/vk/publication/modal';
    } else if (instance.type === 'news') {
      if (instance.publicationId) {
        url = `/vk/publication/news/modal/${instance.publicationId}`;
      } else {
        url = '/vk/publication/news/modal';
      }
    }

    const data = {
      group_slot_id: instance.groupSlotId,
      main_slot_id: instance.mainSlotId,
      publication_id: instance.publicationId,
    };
    await axios.post(url, data)
      .then((result) => {
        const element = document.createElement('div');
        element.innerHTML = result.data;

        instance.modal = new Modal(element, {
          preloader: false,
          closerText: 'x',
          useAjaxForm: true,
          closeOnClickBg: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

document.addEventListener('publication:modal:open', (e) => {
  new PublicationForm(e.detail);
});