import { onDomReady } from '../../components/dynamic/observer';
import Jax from '../../components/jax/jax';
import LiveEvent from '../../components/live/live';

class PublicationForm {
  constructor(form) {
    this.form = form;
    this.content = document.querySelector('[data-publication-list]');

    this.debounceOrder = 0;

    this.init();
  }

  init() {
    this.bind();
  }

  bind() {
    this.bindForm();
    this.bindEdit();
  }

  bindEdit() {
    new LiveEvent('click', '[data-open-publication]', (e) => {
      const { type } = e.target.dataset;
      const detail = { type };

      if (type === 'slot') {
        detail.groupSlotId = e.target.dataset.groupSlot;
      } else if (type === 'news') {
        detail.publicationId = e.target.dataset.publicationId;
      }

      document.dispatchEvent(new CustomEvent('publication:modal:open', { detail }));
    });
  }

  bindForm() {
    const instance = this;
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      instance.debounceRequest();
    });

    const inputs = this.form
      .querySelectorAll('input[type="datetime-local"], input[type="checkbox"], input[type="radio"]');
    inputs.forEach((input) => {
      input.addEventListener('change', (e) => {
        instance.debounceRequest();
      });
    });

    document.addEventListener('publication:saved', (e) => instance.debounceRequest());
  }

  debounceRequest() {
    const instance = this;
    this.debounceOrder++;

    setTimeout(() => {
      this.debounceOrder--;
      if (this.debounceOrder <= 0) {
        instance.request();
      }
    }, 400);
  }

  request() {
    this.content.classList.add('_loading');
    const instance = this;
    const { form } = instance;
    const action = form.getAttribute('action');
    const method = form.getAttribute('method');
    const jax = new Jax(action, method, false);

    jax.send(new FormData(form)).then((html) => {
      instance.replaceContent(html);
    });
  }

  replaceContent(html) {
    const page = document.createElement('div');
    page.innerHTML = html;
    const newContent = page.querySelector('[data-publication-list]');
    this.content.innerHTML = newContent.innerHTML;
    this.content.classList.remove('_loading');
  }
}
onDomReady(() => {
  const form = document.querySelector('[data-publication-filter]');
  if (form) {
    new PublicationForm(form);
  }
})