import { onDomChanges } from '../../components/dynamic/observer';

class FormInputTrimmer {
  constructor() {
    this.forms = document.querySelectorAll('form');
    this.init();
  }

  init() {
    this.forms.forEach((form) => {
      const inputs = form.querySelectorAll('input[type="text"], input[type="email"], input[type="password"], textarea');

      inputs.forEach((input) => {
        input.addEventListener('input', this.handleInput.bind(this, input));
        input.addEventListener('paste', this.handlePaste.bind(this, input));
      });
    });
  }

  handleInput(input) {
    this.trimLeadingWhitespace(input);
  }

  handlePaste(input) {
    setTimeout(() => {
      this.trimLeadingWhitespace(input);
    }, 0);
  }

  trimLeadingWhitespace(input) {
    if (input.value) {
      input.value = input.value.replace(/^\s+/, '');
    }
  }
}

onDomChanges(() => {
  new FormInputTrimmer();
});